.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 50px;
    align-items: stretch;
    width: 100%;
    margin: auto auto 25px;
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .grid {
      width: 540px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .grid {
      width: 720px;
    }
  }
  
  @media (min-width: 992px)
  {
    .grid {
      width: 1000px;
    }
  }
  
  @media (min-width: 1200px)
  {
    .grid {
      width: 1140px;
    }
  }
  
  .mycard {
    text-align: center;
    position: relative;
    border-radius: 15px;
    padding: 10px 20px ;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    transition: .5s;
    overflow: hidden;
  }
  
  .mycard:hover {
    transform: scale(1.1);
  }
  
  .basic,
  .basic .title .fa {
    background: #64b5f6;
  }
  .mycard::before {
    content: '';
    position: absolute;
    margin-bottom: 100;
    left: 0;
    width: 100%;
    height: 40%;
   
    z-index: 1;
    transform: skewY(-5deg) scale(1.5);
  }
  
  .title .fa {
    color: #fff;
    font-size: 60px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20);
  }
  
  .title h2 {
    position: relative;
    margin: 20px 0 0;
    color: #fff;
    font-size: 28px;
    z-index: 2;
  }
  
  .price {
    position: relative;
    z-index: 2;
  }
  
  .price h4 {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 60px;
  }
  
  .option {
    position: relative;
    z-index: 2;
  }
  
  .option ul {
    margin: 0;
    padding: 0;
  }
  
  .option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
  }
  
  .mycard {
    position: relative;
    z-index: 2;
    border-radius: 8px;
    margin: 30px auto 8px;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.20);
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
    font-weight: bold;
  }
