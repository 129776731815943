



  details {
    width: 80%;
    min-height: 5px;
    max-width: auto;
    padding: 8px;
    margin: 0 auto;
    position: relative;
    font-size: 22px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 15px;
    box-sizing: border-box;
    transition: all .3s;
  }
  
  details + details {
    margin-top: 20px;
  }
  
  details[open] {
    min-height: 50px;
    background-color: #f6f7f8;
    // box-shadow: 1px 20px 10px darkgrey;
  }
  
  summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    padding:0px 8px;
    transition: all .3s;
  }
  
  summary:focus {
    outline: none;
   
  }
  
  // summary::-webkit-details-marker {
  //   display: none
  // }
  
  .control-icon {
    fill: rebeccapurple;
    transition: .3s ease;
    pointer-events: none;
  }
  
  .control-icon-close {
    display: none;
  }
  
  summary[open] .control-icon-close {
    display: initial;
    transition: .3s ease;
  }
  
  summary[open] .control-icon-expand {
    display: none;
  }
  
  summary[open] summary:hover::after {
    animation: pulse 1s ease;
  }
  
  @keyframes pulse {
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @media (max-width:425px){
      details{
        width:100%;
        min-height: auto;
        padding: 8px;
        margin: 0 -40px auto -10px;
       
      }
  }
  @media (max-width:375px){
    details{
      width:100%;
      min-height: auto;
      padding: 8px;
      margin: 0 -60px auto -8px;
     
    }
}
@media (max-width:320px){
    details{
      width:100%;
      min-height: auto;
      padding: 8px;
      margin: 0  -40px auto;
     
    }
}